body {
  font-family: sans-serif;
  font-weight: 100;
  color: #333534;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Questrial', 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

button {
  font-family: 'Questrial', sans-serif;
}

header.masthead {
  position: relative;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
  color: #333534;
}

header.masthead .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
}

.call-to-action {
  position: relative;
  background-color: #333534;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
  margin-bottom: 1%;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #333534;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.signup-button {
  text-decoration: none !important;
}

.selling-point-icon {
  height: auto;
  width: 20%;
}

.illo {
  height: auto;
  width: 60%;
  margin-top: 5%;
}

.demo-gif {
  height: auto;
  width: 115%;
}

@media (min-width: 1200px) {

  header.text-center {
    text-align: left !important;
  }

  .demo-gif {
    position: absolute;
    top: -165px;
  }

  .button-container {
    padding-left: 0 !important;
  }
  
}

@media (max-width: 1199px) and (min-width: 992px) {
  header.text-center {
    text-align: left !important;
  }
  .demo-gif {
    position: absolute;
    top: -80px;
  }
  .button-container {
    padding-left: 0 !important;
  }
  
  .showcase .showcase-img {
    min-height: 30rem;
    background-size: cover;
  }

}

@media (max-width: 991px) {
  .demo-gif {
    width: 90%;
    margin-left: 6.8%;
  }
  
  header.masthead {
    padding-top: 0;
    padding-bottom: 4rem;
  }
  
  header.masthead h1 {
    font-size: 2.5rem;
  }

  body {
    text-align: center !important;
  }

  .btn-block {
    width: 50%;
    margin: auto;
  }

  .landing-lead-in {
    margin: 4% 10% 8% 10% !important;
  }

  .features-icons {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  h2 {
    margin: 0 10%;
  }

  .testimonials .testimonial-item img {
    max-width: 10rem;
  }

  section.showcase {
    padding-bottom: 1.5rem;
  }

  section.testimonials {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }

}

.btn-primary {
  background-color: #00a180;
  border-color: #00a180;
  border-radius: 2em;
}

.btn-primary:hover {
  background-color: #17d2a5;
  border-color: #17d2a5;
}

.lead {
  font-size: 1.1em;
}