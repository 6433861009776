.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

.container {
  flex: 1 0 auto;
  padding: 0;
}

footer {
  flex-shrink: 0;
  margin-top: auto;
  font-size: 0.9em;
}

.header-logo {
  margin: 4%;
  height: 1.8em;
  width: auto;
}

.bg-dark {
  background-color: #333534 !important;
}

.nav-link {
  color: #cccccc !important;
  font-family: Questrial, sans-serif;
}

.nav-link:hover {
  color: #17D2A5 !important;
  font-family: Questrial, sans-serif;
}

label {
  font-family: Questrial, sans-serif;
}

::placeholder {
  color: #cccccc !important;
  font-family: sans-serif;
  font-weight: 100;
}

input, textarea {
  font-family: sans-serif;
  font-weight: 100;
}

.form-control {
  border-radius: 0;
}

.signup-button {
  width: 40%;
}

a {
  color: #00A180;
}

a:hover {
  color: #00A180;
}

.text-primary {
  color: #00A180 !important;
}

.swal-button {
  background-color: #00A180;
}

.swal-button:hover {
  background-color: #17D2A5 !important;
}

.swal-footer {
  text-align: center;
}

.swal-text {
  font-weight: 100;
}

.floater-text {
  font-size: 0.9em;
}

.__floater__title {
  font-size: 1em !important;
}

.MuiTypography-root {
  background-color: #17D2A5 !important;
}

.info-page {
  margin: 10% 20% 10% 20%;
}

.info-body {
  text-align: left;
  margin: 2% 5% 5% 5%;
}

.PastQuarters {
  margin: 15%;
}

.BuddyList {
  margin: 15%;
}

.btn-invite-buddy {
  width: 150px;
}