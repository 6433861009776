.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

.container {
  flex: 1 0 auto;
  padding: 0;
}

footer {
  flex-shrink: 0;
  margin-top: auto;
  font-size: 0.9em;
}

.header-logo {
  margin: 4%;
  height: 1.8em;
  width: auto;
}

.bg-dark {
  background-color: #333534 !important;
}

.nav-link {
  color: #cccccc !important;
  font-family: Questrial, sans-serif;
}

.nav-link:hover {
  color: #17D2A5 !important;
  font-family: Questrial, sans-serif;
}

label {
  font-family: Questrial, sans-serif;
}

::-webkit-input-placeholder {
  color: #cccccc !important;
  font-family: sans-serif;
  font-weight: 100;
}

::-ms-input-placeholder {
  color: #cccccc !important;
  font-family: sans-serif;
  font-weight: 100;
}

::placeholder {
  color: #cccccc !important;
  font-family: sans-serif;
  font-weight: 100;
}

input, textarea {
  font-family: sans-serif;
  font-weight: 100;
}

.form-control {
  border-radius: 0;
}

.signup-button {
  width: 40%;
}

a {
  color: #00A180;
}

a:hover {
  color: #00A180;
}

.text-primary {
  color: #00A180 !important;
}

.swal-button {
  background-color: #00A180;
}

.swal-button:hover {
  background-color: #17D2A5 !important;
}

.swal-footer {
  text-align: center;
}

.swal-text {
  font-weight: 100;
}

.floater-text {
  font-size: 0.9em;
}

.__floater__title {
  font-size: 1em !important;
}

.MuiTypography-root {
  background-color: #17D2A5 !important;
}

.info-page {
  margin: 10% 20% 10% 20%;
}

.info-body {
  text-align: left;
  margin: 2% 5% 5% 5%;
}

.PastQuarters {
  margin: 15%;
}

.BuddyList {
  margin: 15%;
}

.btn-invite-buddy {
  width: 150px;
}
body {
  font-family: sans-serif;
  font-weight: 100;
  color: #333534;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Questrial', 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

button {
  font-family: 'Questrial', sans-serif;
}

header.masthead {
  position: relative;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
  color: #333534;
}

header.masthead .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
}

.call-to-action {
  position: relative;
  background-color: #333534;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
  margin-bottom: 1%;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #333534;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.signup-button {
  text-decoration: none !important;
}

.selling-point-icon {
  height: auto;
  width: 20%;
}

.illo {
  height: auto;
  width: 60%;
  margin-top: 5%;
}

.demo-gif {
  height: auto;
  width: 115%;
}

@media (min-width: 1200px) {

  header.text-center {
    text-align: left !important;
  }

  .demo-gif {
    position: absolute;
    top: -165px;
  }

  .button-container {
    padding-left: 0 !important;
  }
  
}

@media (max-width: 1199px) and (min-width: 992px) {
  header.text-center {
    text-align: left !important;
  }
  .demo-gif {
    position: absolute;
    top: -80px;
  }
  .button-container {
    padding-left: 0 !important;
  }
  
  .showcase .showcase-img {
    min-height: 30rem;
    background-size: cover;
  }

}

@media (max-width: 991px) {
  .demo-gif {
    width: 90%;
    margin-left: 6.8%;
  }
  
  header.masthead {
    padding-top: 0;
    padding-bottom: 4rem;
  }
  
  header.masthead h1 {
    font-size: 2.5rem;
  }

  body {
    text-align: center !important;
  }

  .btn-block {
    width: 50%;
    margin: auto;
  }

  .landing-lead-in {
    margin: 4% 10% 8% 10% !important;
  }

  .features-icons {
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

  h2 {
    margin: 0 10%;
  }

  .testimonials .testimonial-item img {
    max-width: 10rem;
  }

  section.showcase {
    padding-bottom: 1.5rem;
  }

  section.testimonials {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }

}

.btn-primary {
  background-color: #00a180;
  border-color: #00a180;
  border-radius: 2em;
}

.btn-primary:hover {
  background-color: #17d2a5;
  border-color: #17d2a5;
}

.lead {
  font-size: 1.1em;
}
.table td {
  padding: 5px 2px 5px 2px !important;
}

.current-week {
  background-color: #f0f0f0;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #f0f0f0;
}

.comment-input {
  padding-bottom: 1em;
  height: 25%;
  resize: none;
}

.tool-tip-wrapper {
  position: absolute;
  margin-left: 1%;
}

table {
  color: #333534 !important;
}

.row-content {
  height: 24px;
}

.progress-bar-container {
  width: 100%;
  height: 24px;
  background-color: #f0f0f0;
  border-radius: 2px;
}

.future-week {
  color: #cccccc;
}

.setokrs-container {
    padding: 0 15% 0 15% !important;
}

.input-obj-title {
  float: left;
  width: 93%;
  margin-top: 1%;
  padding: 5px;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.input-kr-target {
  width: 10%;
  margin: 1%;
  padding: 5px;
}

.input-kr-title {
  width: 75%;
  margin: 1%;
  padding: 5px;
}

@media (max-width: 990px) {
  .input-obj-title {
    width: 85%;
  }

  .input-kr-title {
    width: 65%;
  }

  .btn-danger {
    margin-top: 2.5%;
  }

  .setokrs-container {
    padding: 0 !important;
  }
}

.btn-danger {
  border-radius: 2em;
  background-color: #ff463c;
  height: 2em;
  width: 2em;
  font-weight: 800;
  margin-top: 2%;
}

.key-result {
  display: block !important;
}

ol {
  padding-left: 3%;
}

.btn-secondary {
  border-radius: 2em;
  background-color: #00A180;
  height: 2em;
  width: 2em;
  font-weight: 800;
  margin-top: 2%;
  border-color: #00A180;
}

.btn-secondary:hover {
  background-color: #17D2A5;
  border-color: #17D2A5;
}

.btn-sm {
  padding: 0px !important;
}

.add-key-result-button {
  display: block;
  font-style: italic;
}

.add-key-result-button span {
  color: #999999;
}


.kr-card {
  text-align: left;
}

.btn-add-objective {
  width: 25%;
}

.btn-done {
  background-color: #333534;
  border-color: #333534;
  color: #ffffff;
  width: 15%;
  margin-bottom: 10%;
}

.kr-link-edit-button {
  cursor: pointer;
}

.settings-icon {
  width: 20px;
  fill: #666;
}

.settings-icon:hover {
  fill: #17D2A5 !important;
}

h5 {
  margin-top: 10%;
}

.settings-input-link{
  width: 60%;
  padding: 5px;
}

.settings-helper-text {
  margin: 5%;
}

.settings-input-radio-wrapper {
  text-align: left;
  padding-left: 33%;
}

.typeform-widget-container {
  display: block;
  position: relative;
  height: 600px;
}
