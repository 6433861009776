.table td {
  padding: 5px 2px 5px 2px !important;
}

.current-week {
  background-color: #f0f0f0;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #f0f0f0;
}

.comment-input {
  padding-bottom: 1em;
  height: 25%;
  resize: none;
}

.tool-tip-wrapper {
  position: absolute;
  margin-left: 1%;
}

table {
  color: #333534 !important;
}

.row-content {
  height: 24px;
}

.progress-bar-container {
  width: 100%;
  height: 24px;
  background-color: #f0f0f0;
  border-radius: 2px;
}

.future-week {
  color: #cccccc;
}
