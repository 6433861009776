.setokrs-container {
    padding: 0 15% 0 15% !important;
}

.input-obj-title {
  float: left;
  width: 93%;
  margin-top: 1%;
  padding: 5px;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.input-kr-target {
  width: 10%;
  margin: 1%;
  padding: 5px;
}

.input-kr-title {
  width: 75%;
  margin: 1%;
  padding: 5px;
}

@media (max-width: 990px) {
  .input-obj-title {
    width: 85%;
  }

  .input-kr-title {
    width: 65%;
  }

  .btn-danger {
    margin-top: 2.5%;
  }

  .setokrs-container {
    padding: 0 !important;
  }
}

.btn-danger {
  border-radius: 2em;
  background-color: #ff463c;
  height: 2em;
  width: 2em;
  font-weight: 800;
  margin-top: 2%;
}

.key-result {
  display: block !important;
}

ol {
  padding-left: 3%;
}

.btn-secondary {
  border-radius: 2em;
  background-color: #00A180;
  height: 2em;
  width: 2em;
  font-weight: 800;
  margin-top: 2%;
  border-color: #00A180;
}

.btn-secondary:hover {
  background-color: #17D2A5;
  border-color: #17D2A5;
}

.btn-sm {
  padding: 0px !important;
}

.add-key-result-button {
  display: block;
  font-style: italic;
}

.add-key-result-button span {
  color: #999999;
}


.kr-card {
  text-align: left;
}

.btn-add-objective {
  width: 25%;
}

.btn-done {
  background-color: #333534;
  border-color: #333534;
  color: #ffffff;
  width: 15%;
  margin-bottom: 10%;
}

.kr-link-edit-button {
  cursor: pointer;
}

.settings-icon {
  width: 20px;
  fill: #666;
}

.settings-icon:hover {
  fill: #17D2A5 !important;
}

h5 {
  margin-top: 10%;
}

.settings-input-link{
  width: 60%;
  padding: 5px;
}

.settings-helper-text {
  margin: 5%;
}

.settings-input-radio-wrapper {
  text-align: left;
  padding-left: 33%;
}

.typeform-widget-container {
  display: block;
  position: relative;
  height: 600px;
}